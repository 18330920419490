import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
    // staff

    {
        path: '*',
        name: 'login2',
        component: () => import('../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/staff/select_center',
        name: 'select_center',
        component: () => import('../views/auth/select-center.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/staff/',
        name: 'home',
        component: () => import('../views/center/index.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/test',
    //     name: 'test',
    //     component: () => import('../views/center/test.vue'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/home',
        name: 'home2',
        component: () => import('../views/center/index.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/login',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/staff/resetpassword/:token',
        name: 'resetpassword',
        component: () => import('../views/auth/resetpassword.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/staff/password-recovery',
        name: 'password-recovery',
        component: () => import('../views/auth/pass_recovery.vue'),
        meta: { layout: 'auth' },
    },

    {
        path: '/staff/clover-testing',
        name: 'users',
        component: () => import('../views/center/clover-testing.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/users',
        name: 'users',
        component: () => import('../views/center/users.vue'),
        meta: { layout: 'app' },
    },

    {
        path: '/staff/packages',
        name: 'packages',
        component: () => import('../views/center/packages.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/buy-membership',
        name: 'buy-membership',
        component: () => import('../views/center/buy-membership.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/nonetwork',
        name: 'nonetwork',
        component: () => import('../views/center/no_network.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/creditnote',
        name: 'creditnote',
        component: () => import('../views/center/creditnote.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/batch-booking',
    //     name: 'batch-booking',
    //     component: () => import('../views/center/batch-booking.vue'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/buy-membership/:id',
        name: 'buy-membership2',
        component: () => import('../views/center/buy-membership.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/active-membership',
        name: 'active-membership',
        component: () => import('../views/center/active-membership.vue'),
        meta: { layout: 'app' },
    },

    {
        path: '/staff/class-master',
        name: 'class-master',
        component: () => import('../views/center/class-master.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/pt-master',
        name: 'pt-master',
        component: () => import('../views/center/pt-master.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/pay-as-you-go',
        name: 'pay-as-you-go',
        component: () => import('../views/center/pay-as-you-go.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/class-master/:id',
        name: 'class-master-detail',
        component: () => import('../views/center/class-master-detail.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/venue-master-details/:id',
    //     name: 'venue-master-details',
    //     component: () => import('../views/center/venue-master-details.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/level-master',
    //     name: 'level-master',
    //     component: () => import('../views/center/level-master.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/session-master',
    //     name: 'session-master',
    //     component: () => import('../views/center/session-master.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/service-master',
    //     name: 'service-master',
    //     component: () => import('../views/center/service-master.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/price-group',
    //     name: 'price-group',
    //     component: () => import('../views/center/price-group.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/service-sessions',
    //     name: 'service-sessions',
    //     component: () => import('../views/center/service-sessions.vue'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/customers',
        name: 'customers',
        component: () => import('../views/center/customers.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/auto_subscription_report',
        name: 'auto_subscription_report',
        component: () => import('../views/center/auto_subscription_report.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/customers/:id',
        name: 'customer_det',
        component: () => import('../views/center/customers_det.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/service-booking',
    //     name: 'service-booking',
    //     component: () => import('../views/center/service-booking.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/service-booking/:id',
    //     name: 'service-booking1',
    //     component: () => import('../views/center/service-booking.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/cancel-booking',
    //     name: 'cancel-booking',
    //     component: () => import('../views/center/cancel-booking.vue'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/upcomming-booking',
        name: 'upcomming-booking',
        component: () => import('../views/center/upcomming-booking'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/refund_report',
        name: 'refund_report',
        component: () => import('../views/center/refundreport'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/past-booking',
    //     name: 'past-booking',
    //     component: () => import('../views/center/past-booking'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/venues',
        name: 'venues',
        component: () => import('../views/center/venues.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/venues/:id',
        name: 'pool',
        component: () => import('../views/center/venue-exception.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/pool',
        name: 'pool',
        component: () => import('../views/center/pool.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/pool/:id',
        name: 'pool',
        component: () => import('../views/center/pool-exception.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/waitinglist',
        name: 'waitinglist',
        component: () => import('../views/center/waitinglist.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/invoice',
    //     name: 'invoices',
    //     component: () => import('../views/center/invoice'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/paylater-invoice',
    //     name: 'paylater-invoices',
    //     component: () => import('../views/center/paylater-invoices.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/batchbooking-payment',
    //     name: 'paylater-invoices',
    //     component: () => import('../views/center/batchbooking-payment.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/print-preview',
    //     name: 'print',
    //     component: () => import('../views/center/print-preview.vue'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/invoicedetails/:id',
        name: 'invoicedetails',
        component: () => import('../views/center/invoicedetails.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/bookings',
    //     name: 'bookings',
    //     component: () => import('../views/center/bookings'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/invoice_booking',
        name: 'bookings',
        component: () => import('../views/center/invoice_booking.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/credit_refund',
        name: 'credit_refund',
        component: () => import('../views/center/credit_refund.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/venuebooking',
        name: 'venuebooking',
        component: () => import('../views/center/venuebooking'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/poolbooking',
        name: 'poolbooking',
        component: () => import('../views/center/poolbooking'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/course-booking',
        name: 'course-booking',
        component: () => import('../views/center/course-booking.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/class-booking',
        name: 'class-booking',
        component: () => import('../views/center/class-booking.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/calender',
    //     name: 'calender',
    //     component: () => import('../views/center/calender.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/facility-booking',
    //     name: 'facility-booking',
    //     component: () => import('../views/center/facility-booking.vue'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/clover-device',
        name: 'clover-device',
        component: () => import('../views/center/clover-device.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/vat-setup',
    //     name: 'vat-setup',
    //     component: () => import('../views/center/vat-setup.vue'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/email-template',
        name: 'email-template',
        component: () => import('../views/center/email-template.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/email-template/:id',
        name: 'email-templatedetail',
        component: () => import('../views/center/email-template-detailpage.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/pos',
        name: 'pos',
        component: () => import('../views/center/pos.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/unallocated-courses',
        name: 'unallocated_course',
        component: () => import('../views/center/unallocated-courses.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/purchase',
        name: 'purchase',
        component: () => import('../views/center/purchase.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/current-stock',
        name: 'current-stock',
        component: () => import('../views/center/current-stock.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/store-audit',
        name: 'store-audit',
        component: () => import('../views/center/store-audit.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/Class_Booking_list',
        name: 'Class_Booking_list',
        component: () => import('../views/center/classbookinglist.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/check-in',
        name: 'check-in',
        component: () => import('../views/center/check-in.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/checkinreport',
        name: 'checkinreport',
        component: () => import('../views/center/checkin_report.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/profile',
        name: 'profile',
        component: () => import('../views/center/profile.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/stock_report',
        name: 'stock_report',
        component: () => import('../views/center/stock_report.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/revenue_report',
        name: 'revenue_report',
        component: () => import('../views/center/revenue_report.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/vat_report',
        name: 'vat_report',
        component: () => import('../views/center/vat_report.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/refundvouchers',
    //     name: 'refundvouchers',
    //     component: () => import('../views/center/refundvouchers.vue'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/CustomerCheckIn/:date',
        name: 'CustomerCheckIn',
        component: () => import('../views/center/CustomerCheckIn.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/voucher',
        name: 'voucher',
        component: () => import('../views/center/vouchers.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/products',
        name: 'product',
        component: () => import('../views/center/products.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/category',
        name: 'category',
        component: () => import('../views/center/category.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/app-settings',
        name: 'app-setting',
        component: () => import('../views/center/app-setting.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/activemembershipdetailpage/:id',
        name: 'activemembershipdetailpage',
        component: () => import('../views/center/active-membership-detailpage.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/authipay-settings',
        name: 'authipay-settings',
        component: () => import('../views/center/authipay-settings.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/poolsales',
        name: 'poolsales',
        component: () => import('../views/center/poolSales.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/venuesales',
        name: 'venuesales',
        component: () => import('../views/center/venueSales.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/classsales',
        name: 'classsales',
        component: () => import('../views/center/classSales.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/paylater',
        name: 'paylater',
        component: () => import('../views/center/paylater.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/coursesales',
        name: 'coursesales',
        component: () => import('../views/center/courseSales.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/membershipsales',
        name: 'membershipsales',
        component: () => import('../views/center/membershipSales.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/retailsales',
        name: 'retailsales',
        component: () => import('../views/center/retailSales.vue'),
        meta: { layout: 'app' },
    },
    /*{
        path: '/staff/membership',
        name: 'membership',
        component: () => import('../views/center/coming-soon.vue'),
        meta: { layout: 'app' }
    },*/
    {
        path: '/staff/course-master',
        name: 'course-master',
        component: () => import('../views/center/course-master.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/booked-courses',
        name: 'booked-courses',
        component: () => import('../views/center/booked-courses.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/booked-course/:id',
    //     name: 'booked-coursedetailpage',
    //     component: () => import('../views/center/booked-coursedetailpage.vue'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/course-master/:id',
        name: 'course-master-detail',
        component: () => import('../views/center/course-batch-master.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/refund-list',
    //     name: 'refund-list',
    //     component: () => import('../views/center/refund-list.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/subscriptions',
    //     name: 'subscriptions',
    //     component: () => import('../views/center/subscriptions.vue'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/banner',
        name: 'banner',
        component: () => import('../views/center/banner.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/faq',
        name: 'faq',
        component: () => import('../views/center/faq.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/widget-settings',
        name: 'widget-settings',
        component: () => import('../views/center/widget-settings.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/purchase-report',
    //     name: 'purchase-report',
    //     component: () => import('../views/center/purchase-report.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/sale-summary',
    //     name: 'purchase-report',
    //     component: () => import('../views/center/sale-summary.vue'),
    //     meta: { layout: 'app' },
    // },
    {
        path: '/staff/reports',
        name: 'x-report',
        component: () => import('../views/center/reports.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/x-report',
        name: 'x-report',
        component: () => import('../views/center/x-report.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/cashsheet_report',
        name: 'cashsheet_report',
        component: () => import('../views/center/cashsheet.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/category_report',
        name: 'category_report',
        component: () => import('../views/center/category_report.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/audit_report',
        name: 'audit_report',
        component: () => import('../views/center/audit_report.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/deferred_income',
        name: 'deferred_income',
        component: () => import('../views/center/deferred_report.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/deferred_income_course',
        name: 'deferred_income_course',
        component: () => import('../views/center/deferred_course_report.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/cancellation_&_refund',
        name: 'cancellation_without_refund',
        component: () => import('../views/center/cancellation-without-refund.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/membership_report',
        name: 'membership_report',
        component: () => import('../views/center/membership_report.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/service_announcement',
        name: 'service_announcement',
        component: () => import('../views/center/service_announcement.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/poolbookinglist',
        name: 'poolbookinglist',
        component: () => import('../views/center/poolbookinglist.vue'),
        meta: { layout: 'app' },
    },
    {
        path: '/staff/pool_batch_booking',
        name: 'pool_batch_booking',
        component: () => import('../views/center/pool_batch_booking.vue'),
        meta: { layout: 'app' },
    },
    // {
    //     path: '/staff/z-report',
    //     name: 'z-report',
    //     component: () => import('../views/center/z-report.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/coming-soon',
    //     name: 'coming-soon',
    //     component: () => import('../views/center/coming-soon.vue'),
    //     meta: { layout: 'app' },
    // },
    // {
    //     path: '/staff/stripetest',
    //     name: 'stripetest',
    //     component: () => import('../views/center/stripetest.vue'),
    //     meta: { layout: 'app' },
    // },

    // customer

    // {
    //     path: '/customer/home',
    //     name: 'home',
    //     component: () => import('../views/customer/home.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/coursebooking',
    //     name: 'home',
    //     component: () => import('../views/customer/coursebooking.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/subscription',
    //     name: 'subscription',
    //     component: () => import('../views/customer/subscription.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/pageloader',
    //     name: 'pageloader',
    //     component: () => import('../views/customer/pageloader.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/access',
    //     name: 'Access',
    //     component: () => import('../views/customer/Access.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/cart',
    //     name: 'cart',
    //     component: () => import('../views/customer/cartview.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/account',
    //     name: 'account',
    //     component: () => import('../views/customer/account.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/bookingview',
    //     name: 'bookingview',
    //     component: () => import('../views/customer/bookingview.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/facilitiesbooking',
    //     name: 'facilities',
    //     component: () => import('../views/customer/facilitiesbooking.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/membershipbooking',
    //     name: 'membershipbooking',
    //     component: () => import('../views/customer/membershipbooking.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/upcommingview',
    //     name: 'upcommingview',
    //     component: () => import('../views/customer/upcommingview.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/pastview',
    //     name: 'pastview',
    //     component: () => import('../views/customer/pastview.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/invoice',
    //     name: 'invoice',
    //     component: () => import('../views/customer/invoice.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/venues',
    //     name: 'venues',
    //     component: () => import('../views/customer/venues.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/poolbooking',
    //     name: 'poolbooking',
    //     component: () => import('../views/customer/poolbooking.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/paymentmethod',
    //     name: 'paymentmethod',
    //     component: () => import('../views/customer/paymentmethod.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/debitcard',
    //     name: 'debitcard',
    //     component: () => import('../views/customer/debitcard.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/cartview',
    //     name: 'cartview',
    //     component: () => import('../views/customer/cartview.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/privacypolicy',
    //     name: 'privacypolicy',
    //     component: () => import('../views/customer/privacypolicy.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/terms',
    //     name: 'terms',
    //     component: () => import('../views/customer/terms.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/callsupport',
    //     name: 'callsupport',
    //     component: () => import('../views/customer/callsupport.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/faq',
    //     name: 'faq',
    //     component: () => import('../views/customer/faq.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/purchasecourses',
    //     name: 'purchasecourses',
    //     component: () => import('../views/customer/purchasecourses.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/courses',
    //     name: 'courses',
    //     component: () => import('../views/customer/courses.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/jellyfish',
    //     name: 'jellyfish',
    //     component: () => import('../views/customer/jellyfish.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/history',
    //     name: 'history',
    //     component: () => import('../views/customer/history.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/family',
    //     name: 'family',
    //     component: () => import('../views/customer/family.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/addfamily',
    //     name: 'addfamily',
    //     component: () => import('../views/customer/addfamily.vue'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/forgotpassword',
    //     name: 'forgotpassword',
    //     component: () => import('../views/customer/forgotpassword.vue'),
    //     meta: { layout: 'cust_auth' },
    // },
    // {
    //     path: '/customer/register',
    //     name: 'register',
    //     component: () => import('../views/customer/register.vue'),
    //     meta: { layout: 'cust_auth' },
    // },
    // {
    //     path: '/customer/login',
    //     name: 'login',
    //     component: () => import('../views/customer/login.vue'),
    //     meta: { layout: 'cust_auth' },
    // },
    // {
    //     path: '/customer/familymemberdetail',
    //     name: 'familymemberdetail',
    //     component: () => import('../views/customer/familymemberdetail'),
    //     meta: { layout: 'cust' },
    // },
    // {
    //     path: '/customer/accounteditpage/:id',
    //     name: 'accounteditpage',
    //     component: () => import('../views/customer/accounteditpage'),
    //     meta: { layout: 'cust' },
    // },

    // {
    //     path: '/customer/QRcode',
    //     name: 'QRcode',
    //     component: () => import('../views/customer/QRcode'),
    //     meta: { layout: 'cust' },
    // },

    // {
    //     path: '*', name: 'landing',
    //     component: () => import('../views/auth/landing.vue'),
    //      meta: { layout: 'auth' }
    // }
];

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
        next(true);
    } else if (to.meta && to.meta.layout && to.meta.layout == 'app') {
        store.commit('setLayout', 'app');
        next(true);
    }
    // else if (to.meta && to.meta.layout && to.meta.layout == 'cust') {
    //     store.commit('setLayout', 'cust');
    //     next(true);
    // } else if (to.meta && to.meta.layout && to.meta.layout == 'cust_auth') {
    //     store.commit('setLayout', 'cust_auth');
    //     next(true);
    // }
    next(false);
});

export default router;
